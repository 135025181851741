import { graphql, useStaticQuery } from "gatsby"

export const fetchTermsMarkdown = () => {
  return useStaticQuery(graphql`
    query TermsMarkdownQuery {
      markdownRemark(frontmatter: { slug: { eq: "/terms" } }) {
        id
        frontmatter {
          title
          slug
          date
        }
        html
      }
    }
  `)
}
